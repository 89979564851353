import React from 'react';
import './index.scss';

function CityCard({name, imageSrc, url, style}) {
  return (
    <div className="CityCard" style={{...style}} onClick={() => window.location.href = url}>
      <div className="container">
        <div className="name">{name}</div>
        <img className="picture" src={imageSrc} alt={name} />
      </div>
    </div>
  )
}

export default CityCard;