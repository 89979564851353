import React from 'react';
import CookieConsent from "react-cookie-consent";
import './index.scss';

const ConsentCookies = ({backgroundColor, children}) => {
  return (
    <CookieConsent
      location="bottom"
      enableDeclineButton
      buttonText="Accepter"
      declineButtonText="Refuser"
      cookieName="cookieConsent"
      containerClasses={"CookieConsent"}
      contentClasses={"cookieContent"}
      buttonWrapperClasses={"cookieButtonWrapper"}
      buttonClasses={"cookieButton"}
      declineButtonClasses={"cookieDeclineButton"}
      style={{ background: backgroundColor || "" }}
      flipButtons
      expires={150}
    >
      {children}
    </CookieConsent>
  )
}

export default ConsentCookies;