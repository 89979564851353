import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown} from '@fortawesome/free-solid-svg-icons';
import {scrollToSmoothly} from "../../../tools";
import LanguageSwitcher from '../../../components/LanguageSwitcher';
import Context from '../../../tools/context';
import './index.scss';

function Header({title, descriptions, backgroundSrc, logoSrc, style}) {
  return (
    <header
      className="Header"
      style={{
        backgroundImage: `url(${backgroundSrc})`,
        textAlign: style.header?.textAlign ||''
      }}
    >
      {Context.platformData?.features?.languageSwitcher &&
        <div className={"languageWrapper"}>
          <LanguageSwitcher foregroundColor={style.secondaryColor} backgroundColor={"#fff"}/>
        </div>
      }
      <nav className="nav" style={{justifyContent: style.header?.logoAlign || ''}}>
        <div className="logo">
          <img src={logoSrc} alt="logo" style={{maxHeight: style.header?.logoHeight || ''}} />
        </div>
      </nav>
      <div className="container" style={{alignItems: style.header?.contentAlign || ''}}>
        <div className="content">
          {title && <h1 className={"title"} style={{fontFamily: style.primaryFont}}>{title}</h1>}
          {descriptions && descriptions.map((description, index) => (
            <p className={"description"} key={index} style={{fontFamily: style.primaryFont}}>{description}</p>
          ))}
        </div>
        <div className="scrollWrapper">
          <button
            className="scrollButton"
            onClick={() => scrollToSmoothly(window.innerHeight, 1000)}
            style={{color: style.secondaryColor || '#183E2F'}}
          >
            {Context.platformData?.features?.scrollDown ? Context.platformData?.features?.scrollDown : ''}
            <FontAwesomeIcon icon={faAngleDown} color={style.secondaryColor || '#183E2F'} size={'lg'}/>
          </button>
        </div>
      </div>
   </header>
  )
}

export default Header;