import React, {useState} from 'react';
import LoadingScreen from '../../components/LoadingScreen';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
import ConsentCookies from '../../components/ConsentCookies';
import Context from '../../tools/context';
import './index.scss';

const Landing = () => {
  const platformData = Context.platformData;

  window.onbeforeunload = () => {
    window.scrollTo(0, 0);
  }

  return (
    <div className={"Landing"}>
      <LoadingScreen logoSrc={platformData.logoSrc} style={platformData.style} />
      <Header
        title={platformData.title}
        descriptions={platformData.descriptions}
        backgroundSrc={platformData.backgroundSrc}
        logoSrc={platformData.logoSrc}
        style={platformData.style}
      />
      <div className="mainContainer">
        <Content />
        <Footer
          icons={platformData.footer.icons}
          links={platformData.footer.links}
          style={platformData.style}
        />
      </div>
      <ConsentCookies backgroundColor={platformData.secondaryColor}>
        <>
          Ce site internet utilise des cookies pour mesurer l'audience du site et mieux connaitre vos attentes. Paramétrez l'acceptation des cookies grâce aux options ci-contre.
          <br/>
          <a style={{textDecoration: "underline", opacity: "0.7"}} href={platformData.footer.links[1].href}>En savoir plus : Politique de Confidentialité</a>
        </>
      </ConsentCookies>
    </div>
  );
}

export default Landing;
