import React from 'react';
import Context from '../../tools/context';
import './index.scss';

const NotFound = () => {
  return (
    <div className={"NotFound"} style={{backgroundColor: Context.theme.background, color: Context.theme.foreground}}>
      404
    </div>
  );
}

export default NotFound;
