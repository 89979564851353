import React, {useState} from 'react';
import i18n from 'i18next';
import './index.scss';

function LanguageSwitcher({foregroundColor, backgroundColor}) {
  const [language, setLanguage] = useState(i18n.language);

  const switchLanguage = (language) => {
    setLanguage(language);
    i18n.changeLanguage(language);
  }

  const languages = ['fr', 'en'];

  return (
    <div className="LanguageSwitcher" style={{color: foregroundColor}}>
      {languages.length > 1 && languages.map((l, index) => (
        <button
          key={index}
          onClick={() => switchLanguage(l)}
          style={{
            borderColor: foregroundColor,
            backgroundColor: l === language ? foregroundColor : '',
            color: l === language ? backgroundColor : ''
          }}
        >
          {l}
        </button>
      ))}
    </div>
  )
}

export default LanguageSwitcher;
