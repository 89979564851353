import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import fr from './locales/fr'
import en from './locales/en'

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    react: {
      useSuspense: false
    },
    fallbackLng: 'fr',
    fallbackNS: 'translation',
    debug: false,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
      format: function(value, format) {
        if (value) {
          if (format === 'uppercase') return value.toUpperCase();
          if (format === 'lowercase') return value.toLowerCase();
          if (format === 'capitalize') return (value.charAt(0).toUpperCase() + value.slice(1));
          return value;
        }
      },
    },
    parseMissingKeyHandler: () => null,
    resources: { fr, en }
  })

export default i18n;