import React from 'react';
import './index.scss';

const SearchBar = ({filter, setFilter, placeholder, style}) => {
  return (
    <div className="SearchBar">
      <label
        className={"searchLabel"}
        style={{
          display: filter ? "block" : "",
          color: style.secondaryColor || ''
        }}
      >{placeholder}</label>
      <input
        type="text"
        placeholder={placeholder}
        value={filter || ''}
        className={"searchInput"}
        onChange={(e) => setFilter(e.target.value)}
        style={{
          borderColor: style.primaryColor || ''
        }}
      />
      {filter &&
        <div className="clearSearchButton">
          <button
            onClick={() => setFilter('')}
            style={{
              color: style.secondaryColor || ''
            }}
          >X</button>
        </div>
      }
    </div>
  )
}

export default SearchBar;