const setupIcons = (iconSrc) => {
    let favicon = document.querySelector("link[rel~='icon']");
    if (!favicon) {
        favicon = document.createElement('link');
        favicon.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(favicon);
    }
    favicon.href = iconSrc;

    let icon32 = document.querySelector("link[sizes~='32x32']");
    if (!icon32) {
        icon32 = document.createElement('link');
        icon32.rel = 'icon';
        icon32.sizes = '32x32';
        document.getElementsByTagName('head')[0].appendChild(icon32);
    }
    icon32.href = iconSrc;

    let icon192 = document.querySelector("link[sizes~='192x192']");
    if (!icon192) {
        icon192 = document.createElement('link');
        icon192.rel = 'icon';
        icon192.sizes = '192x192';
        document.getElementsByTagName('head')[0].appendChild(icon192);
    }
    icon192.href = iconSrc;
}

const setupDescription = (pageDescription) => {
    let description = document.querySelector("meta[name~='description']");
    if (!description) {
        description = document.createElement('meta');
        description.name = 'description';
        document.getElementsByTagName('head')[0].appendChild(description);
    }
    description.content = pageDescription;
}

const setupOg = (pageTitle, pageDescription, pageUrl, pageName, pageIconSrc) => {
    const data = [
        {label: 'title', value: pageTitle},
        {label: 'description', value: pageDescription},
        {label: 'url', value: pageUrl},
        {label: 'site_name', value: pageName},
        {label: 'image', value: pageIconSrc},
    ]

    data.map(({label, value}) => {
        let og = document.querySelector(`meta[property~='og:${label}']`);
        if (!og) {
            og = document.createElement('meta');
            og.property = `og:${label}`;
            document.getElementsByTagName('head')[0].appendChild(og);
        }
        return og.content = value;
    })
}

const setupCanonical = (pageUrl) => {
    let canonical = document.querySelector("link[rel~='canonical']");
    if (!canonical) {
        canonical = document.createElement('link');
        canonical.rel = 'canonical';
        document.getElementsByTagName('head')[0].appendChild(canonical);
    }
    canonical.href = pageUrl;
}

const setupPageHead = (platformData) => {
    document.title = platformData.pageTitle;

    setupIcons(platformData.logoSrc);
    setupDescription(platformData.pageDescription);
    setupOg(platformData.pageTitle, platformData.pageDescription, window.location.href, platformData.name, platformData.logoSrc);
    setupCanonical(window.location.href);
}

export default setupPageHead;
