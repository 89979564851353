import React from 'react';
import './index.scss';

function Footer({icons, links}) {
  return (
    <div className="Footer">
      <div className="icons">
        {icons.map((icon, index) => (
          <img className={"icon"} key={index} src={icon.src} alt={icon.alt} />
        ))}
      </div>
      <div className="links">
        {links.map((link, index) => (
          <a
            className={"link"}
            key={index}
            href={link.href}
            target={link.blank ? '_blank' : ''}
            rel={link.blank ? 'noreferrer' : ''}
          >
            {link.name}
          </a>
        ))}
      </div>
   </div>
  )
}

export default Footer;