import React from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './index.scss';

const Button = (
  {
    className,
    children,
    icon,
    action,
    disable,
    style
  }) => {
  return (
    <div className={"Button"}>
      <button
        type={"button"}
        onClick={action}
        className={`default-btn ${className ? className : ''}`}
        disabled={disable}
        style={{...style}}
      >
        <>
          {icon && <FontAwesomeIcon {...icon} />}
          {children}
        </>
      </button>
    </div>
  );
};

export default Button;