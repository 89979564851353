import platformData  from "../config/ticket-pei.json";

const Context = {
  platformData,
  theme: {
    background: "#000",
    foreground: "#fff",
  }
};

export default Context;
